import React from "react";

const useIsUserIdle: () => boolean = () => {
    const [isIdle, setIsIdle] = React.useState<boolean>(false);

    const _onMouseMove = React.useCallback(
        (e: MouseEvent) => {
            if (isIdle === true) setIsIdle(false);
        },
        [isIdle]
    );

    React.useEffect(() => {
        const timeout = setTimeout(() => setIsIdle(true), 30000);
        return () => clearTimeout(timeout);
    }, [isIdle]);

    React.useEffect(() => {
        // listen for mouse events
        window.addEventListener("mousemove", _onMouseMove);
    }, [_onMouseMove]);

    return isIdle;
};

export default useIsUserIdle;
