import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Input, Button, Select, List, ListItem} from "@appkit4/react-components";
import {Checkbox} from "@appkit4/react-components/checkbox";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {TextArea} from "@appkit4/react-components/field";
import {Question, QuestionType} from "../../../../types/Question";
import {Questionnaire} from "../../../../types/Questionnaire";
import {QuestionTypes, adjustment_types} from "../../../../Constants";
import {QuestionGroup} from "../../../../types/QuestionGroup";
import {useTr} from "../../../../utils/trUtil";
import {FileTemplate} from "../../../../types/FileTemplate";
import Collapse from "react-bootstrap/Collapse";
import FileTemplateForm from "./FileTemplateForm";
import {QuestionView} from "../../../../types/QuestionView";
import {useFixtureStore} from "../../../../stores/FixtureStore";

declare type PropTypes = {
    question?: Question;
    questionnaire: Questionnaire;
    onSaveHandler?: () => void;
    isCopyForm?: boolean;
};

const QuestionForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [templateIsVisible, setTemplateIsVisible] = React.useState<boolean>(false);
    const [question, setQuestion] = React.useState<Question | undefined>(props.question ? props.question : undefined);
    const [questions, setQuestions] = React.useState<Question[]>([]);
    const [questionsAreLoaded, setQuestionsAreLoaded] = React.useState<boolean>(false);
    const [selectedFileTemplate, setSelectedFileTemplate] = React.useState<FileTemplate>();
    const trg = useTr("global");

    const [groups, setGroups] = React.useState<QuestionGroup[]>([]);
    const [groupsAreLoaded, setGroupsAreLoaded] = React.useState<boolean>(false);

    const [views, setViews] = React.useState<QuestionView[]>([]);
    const [viewsAreLoaded, setViewsAreLoaded] = React.useState<boolean>(false);

    const {fileTemplates, init} = useFixtureStore((s) => ({fileTemplates: s.fileTemplates, init: s.init}));

    const [data, setData] = React.useState<Question>({
        id: props.question ? props.question.id : 0,
        name: props.question?.name_en + "",
        name_sk: props.question ? props.question.name_sk : "",
        description: props.question && props.question.description_en ? props.question.description_en : "",
        description_sk: props.question && props.question.description_sk ? props.question.description_sk : "",
        help_text: props.question && props.question.help_text_en ? props.question.help_text_en : "",
        help_text_sk: props.question ? props.question.help_text_sk : "",
        questionnaire: props.questionnaire.id,
        parent: props.question && props.question.parent ? props.question.parent : undefined,
        choices: props.question && props.question.choices ? props.question.choices : [],
        views: props.question && props.question.views ? props.question.views : [],
        templates:
            props.question && props.question.templates?.length
                ? props.question.templates.map((t) => (typeof t === "number" ? t : t.id))
                : [],
        group: props.question && props.question.group ? props.question.group : undefined,
        adjustment_type: props.question && props.question.adjustment_type ? props.question.adjustment_type : null,
        type: props.question && props.question.type ? props.question.type : "input",
        formula: props.question && props.question.formula ? props.question.formula : "",
        priority: props.question && props.question.priority ? props.question.priority : 0,
        attachments_upload_allowed:
            props.question && props.question.attachments_upload_allowed
                ? props.question.attachments_upload_allowed
                : false,
        copy_of: props.question && props.question.copy_of ? props.question.copy_of : undefined,
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint =
            props.question && props.isCopyForm
                ? `/settings/_/fixtures/question/${props.question.id}/copy/`
                : props.question
                ? `/settings/_/fixtures/question/${props.question.id}/`
                : `/settings/_/fixtures/question/`;

        fetchApi(endpoint, props.question && props.isCopyForm ? "post" : props.question ? "patch" : "post", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setQuestion(props.question);
    }, [props.question]);

    // React.useEffect(() => {
    //     console.log(templateIsVisible);
    // }, [templateIsVisible]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (props.questionnaire && questionsAreLoaded === false && visible === true) {
            fetchApi(`/settings/_/fixtures/question/?questionnaire=${props.questionnaire.id}&page=1&page_size=5000`)
                .then((r) => r.json())
                .then((res: any) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setQuestions(res.results);
                    }
                    setQuestionsAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setQuestionsAreLoaded(true);
                });

            fetchApi(`/settings/_/fixtures/question_group/?page=1&page_size=5000`)
                .then((r) => r.json())
                .then((res: any) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setGroups(res.results);
                    }
                    setGroupsAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setGroupsAreLoaded(true);
                });

            fetchApi(`/settings/_/fixtures/question_view/?page=1&page_size=5000`)
                .then((r) => r.json())
                .then((res: any) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setViews(res.results);
                    }
                    setViewsAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setViewsAreLoaded(true);
                });
        }
    }, [questionsAreLoaded, addErrorNotification, fetchApi, props.questionnaire, visible]);

    const renderFileTemplateItem = (item: FileTemplate, index: number) => {
        return (
            <ListItem
                key={index}
                role="radio"
                aria-live="off"
                className="w-100"
                onClick={() => {
                    setSelectedFileTemplate(item);
                    setTemplateIsVisible(true);
                }}
            >
                <span className="primary-text">
                    {item.name} <br /> {item.language} - {item.public_url?.substring(0, 100)}
                </span>
                <span aria-hidden="true" className={"Appkit4-icon icon-pencil-outline"}></span>
            </ListItem>
        );
    };

    return (
        <>
            {question === undefined ? (
                <Button
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    {trg("add")}
                </Button>
            ) : props.isCopyForm ? (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-copy-outline"></span>
                </Button>
            ) : (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            {visible && (
                <Modal
                    visible={visible}
                    title={"Question Form - ID:" + data.id + " (Copy of: " + data.copy_of + ")"}
                    ariaLabel={"Question Form"}
                    onCancel={() => setVisible(false)}
                    modalStyle={{width: "100%", height: "100%"}}
                    footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                    header={
                        question && props.isCopyForm ? (
                            <Badge type={"warning"} value="Copying" style={{marginTop: "-8px"}}></Badge>
                        ) : question ? (
                            <Badge type={"primary"} value="Editing" style={{marginTop: "-8px"}}></Badge>
                        ) : (
                            <Badge type="success" value="Adding" style={{marginTop: "-8px"}}></Badge>
                        )
                    }
                    icons={""}
                    footer={
                        <>
                            <Button onClick={() => setVisible(false)} kind="secondary">
                                {trg("cancel")}
                            </Button>
                            <Button onClick={saveHandler} loading={isLoading}>
                                {trg("save")}
                            </Button>
                        </>
                    }
                    bodyStyle={{minHeight: "calc(100% - 134px)"}}
                >
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <Input
                                type={"text"}
                                title={trg("name_en")}
                                value={data.name}
                                required
                                onChange={(val: string) => setData((s) => ({...s, name: val}))}
                            ></Input>
                        </div>
                        <div className="col-md-6 mt-3">
                            <Input
                                type={"text"}
                                title={trg("name_sk")}
                                value={data.name_sk}
                                required
                                onChange={(val: string) => setData((s) => ({...s, name_sk: val}))}
                            ></Input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <TextArea
                                title={trg("description_en")}
                                required
                                value={data.description}
                                onChange={(val: string) => setData((s) => ({...s, description: val}))}
                            ></TextArea>
                        </div>
                        <div className="col-md-6 mt-3">
                            <TextArea
                                title={trg("description_sk")}
                                required
                                value={data.description_sk}
                                onChange={(val: string) => setData((s) => ({...s, description_sk: val}))}
                            ></TextArea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <TextArea
                                title={trg("help_text_en")}
                                required
                                value={data.help_text}
                                onChange={(val: string) => setData((s) => ({...s, help_text: val}))}
                            ></TextArea>
                        </div>
                        <div className="col-md-6 mt-3">
                            <TextArea
                                title={trg("help_text_sk")}
                                required
                                value={data.help_text_sk}
                                onChange={(val: string) => setData((s) => ({...s, help_text_sk: val}))}
                            ></TextArea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <Select
                                data={groups}
                                value={data.group as number}
                                labelKey="name"
                                valueKey="id"
                                placeholder={trg("question_groups")}
                                dropdownRenderMode="portal"
                                dropdownAlwaysDown={false}
                                onSelect={(val) => {
                                    const v = val as number;
                                    setData((s) => ({...s, group: v}));
                                }}
                            ></Select>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Select
                                data={views}
                                value={data.views}
                                labelKey="name"
                                valueKey="id"
                                placeholder={trg("question_views")}
                                dropdownRenderMode="portal"
                                multiple={true}
                                dropdownAlwaysDown={false}
                                onSelect={(val: any) => {
                                    setData((s) => ({...s, views: val.map((i) => parseInt(i))}));
                                }}
                            ></Select>
                        </div>

                        <div className="col-md-6 mt-3">
                            <Select
                                data={Object.entries(adjustment_types).map(([type, value]) => ({
                                    value: value,
                                    label: trg(value),
                                }))}
                                labelKey="label"
                                valueKey="value"
                                value={data.adjustment_type ? data.adjustment_type : undefined}
                                placeholder={trg("adjustment_type")}
                                defaultActiveFirstOption={false}
                                searchable={true}
                                dropdownRenderMode="portal"
                                dropdownAlwaysDown={false}
                                prefixTemplate={(item) => <>{item.id}</>}
                                onSelect={(val) => {
                                    setData((s) => ({...s, adjustment_type: val + ""}));
                                }}
                            ></Select>
                            <Button
                                compact
                                kind={"text"}
                                className="px-2"
                                onClick={() => setData((s) => ({...s, adjustment_type: ""}))}
                            >
                                {trg("clear_selected")}
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 mt-3">
                            <Select
                                data={QuestionTypes}
                                value={data.type}
                                placeholder="Question Type"
                                dropdownRenderMode="portal"
                                dropdownAlwaysDown={false}
                                onSelect={(val) => {
                                    const v = val as QuestionType;
                                    setData((s) => ({...s, type: v}));
                                }}
                            ></Select>
                            <Checkbox
                                className="mt-2"
                                checked={data.attachments_upload_allowed}
                                onChange={(v) => setData((s) => ({...s, attachments_upload_allowed: v}))}
                            >
                                {trg("attachments_upload_allowed")}
                            </Checkbox>
                        </div>

                        <div className="col-md-4 mt-3">
                            <TextArea
                                title={"Choices"}
                                required
                                value={data.choices.join(",")}
                                onChange={(val: string) => {
                                    const ch = val.split(",") as string[] | number[];
                                    setData((s) => ({...s, choices: ch}));
                                }}
                            ></TextArea>
                            <p>
                                <i>{trg("please_separate")}</i>
                            </p>
                        </div>
                        <div className="col-md-6 mt-3">
                            <Input
                                type={"text"}
                                title={trg("formula")}
                                value={data.formula}
                                required
                                onChange={(val: string) => setData((s) => ({...s, formula: val}))}
                            ></Input>
                            <div className="row">
                                <div className="col-md-2 mt-3">
                                    <Input
                                        type={"number"}
                                        title={trg("priority")}
                                        value={data.priority}
                                        required
                                        onChange={(val: number) => setData((s) => ({...s, priority: val}))}
                                    ></Input>
                                </div>
                                <div className="col-md-10 mt-3">
                                    <Select
                                        data={questions && questions.filter((q: Question) => q.id !== data.id)}
                                        valueKey="id"
                                        labelKey="name"
                                        value={data.parent as number}
                                        placeholder={trg("parent")}
                                        searchable={true}
                                        dropdownRenderMode="portal"
                                        dropdownAlwaysDown={false}
                                        prefixTemplate={(item) => <>{item.id}</>}
                                        onSelect={(val) => {
                                            const q_id = val as number;
                                            setData((s) => ({...s, parent: q_id}));
                                        }}
                                    ></Select>
                                    <Button
                                        compact
                                        kind={"text"}
                                        className="px-2"
                                        onClick={() => setData((s) => ({...s, parent: 0}))}
                                    >
                                        {trg("clear_selected")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="row p-3 border border-2 ms-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Select
                                            data={fileTemplates}
                                            value={data.templates?.map((t) => (typeof t === "number" ? t : t.id))}
                                            labelKey="name"
                                            valueKey="id"
                                            placeholder={trg("file_templates")}
                                            dropdownRenderMode="portal"
                                            searchable={true}
                                            showSelectAll={false}
                                            multiple={true}
                                            dropdownAlwaysDown={false}
                                            onSelect={(val: any) => {
                                                setData((s) => ({...s, templates: val.map((i) => parseInt(i))}));
                                            }}
                                        ></Select>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <Button
                                                    compact
                                                    kind={"text"}
                                                    className="px-2"
                                                    onClick={() => setData((s) => ({...s, templates: []}))}
                                                >
                                                    {trg("clear_selected")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <Button
                                            kind="text"
                                            icon="icon-circle-plus-outline"
                                            onClick={() => {
                                                setTemplateIsVisible(!templateIsVisible);
                                                setSelectedFileTemplate(undefined);
                                            }}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={templateIsVisible}
                                        >
                                            {trg("add_new_template")}
                                        </Button>
                                        <Collapse in={templateIsVisible} dimension="height">
                                            <div id="example-collapse-text">
                                                <div style={{width: "100%"}}>
                                                    <FileTemplateForm
                                                        fileTemplate={selectedFileTemplate}
                                                        onSaveHandler={() => {
                                                            setTemplateIsVisible(false);
                                                            setSelectedFileTemplate(undefined);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h6>{trg("list_of_templates")}</h6>
                                <div style={{maxHeight: 300, overflow: "scroll"}}>
                                    <List
                                        itemKey="id"
                                        bordered={true}
                                        data={fileTemplates}
                                        renderItem={renderFileTemplateItem}
                                        width={"100%"}
                                        style={{display: "inline-block"}}
                                    ></List>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default QuestionForm;
