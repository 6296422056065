import {getCookie} from "./getCookie";
import i18n from "./i18n";
import validator from "validator";

// Example: http://localhost:8000/api/v1
const BACKEND_URI = process.env.REACT_APP_BACKEND_URL! + process.env.REACT_APP_API_VERSION!;
const AUTH_URI = process.env.REACT_APP_AUTH_URL!;
const BASE_BACKEND_URI = process.env.REAklCT_APP_BACKEND_URL!;

export const get_base_backend_uri = () => BASE_BACKEND_URI;
export const get_api_backend_uri = () => BACKEND_URI;
export const trustedDomains = process.env.REACT_APP_TRUSTED_DOMAINS
    ? process.env.REACT_APP_TRUSTED_DOMAINS.split(",")
    : ["corporatetaxflow.sk.pwc.com"];

export const isValidURL = (url: string) => {
    // try {
    //     const parsedUrl = new URL(url);
    //     return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
    // } catch (error) {
    //     return false;
    // }
    return validator.isURL(url, {
        require_tld: false,
        require_protocol: process.env.PUBLIC_URL !== "http://localhost:8000",
    });
};

export const isTrustedDomain = (url: string): boolean => {
    const parsedUrl = new URL(url);
    return trustedDomains.includes(parsedUrl.hostname);
};

export const safeJsonFetch = async (url: string, init?: RequestInit) => {
    if (!isValidURL(url) || !isTrustedDomain(url)) {
        throw new Error("Invalid or untrusted URL");
    }

    const validatedUrl = (url: string) => {
        return new URL(url);
    };

    // Perform the fetch request with the sanitized URL
    const response = await fetch(validatedUrl(url), {
        ...init,
        headers: {
            ...init?.headers,
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken") + "",
        },
    });

    return response;
};
export const boolPromiseResolver = (p: Promise<any>) => p.then(() => true).catch(() => false);

export const safeBackendFetch = async (path: string, init?: any, accessToken?: string) => {
    const headerParams = {
        "Content-Type": "application/json",
    };

    if (init) init["credentials"] = process.env.REACT_APP_CREDENTIALS ? process.env.REACT_APP_CREDENTIALS : undefined;
    if (accessToken) headerParams["Authorization"] = `Bearer ${accessToken}`;
    const CSRFToken = getCookie("csrftoken");
    if (CSRFToken) {
        headerParams["X-CSRFToken"] = CSRFToken;
    }

    if (i18n.language) headerParams["X-Lang"] = i18n.language;

    const response = safeJsonFetch(`${BACKEND_URI}${path}`, {
        ...init,
        headers: {...init?.headers, ...headerParams},
    });

    //console.log((await response).status);

    return response;
};

export const safeAuthFetch = async (path: string, init?: RequestInit) => {
    const modifiedPath = `${AUTH_URI}${path}`;
    return safeJsonFetch(isValidURL(modifiedPath) ? modifiedPath : "", init);
};
